package ui.theme

import androidx.compose.ui.graphics.Color

val primaryDark = Color(0xFFA4C9FE)
val onPrimaryDark = Color(0xFF00315D)
val primaryContainerDark = Color(0xFF204876)
val onPrimaryContainerDark = Color(0xFFD3E3FF)
val secondaryDark = Color(0xFFBCC7DB)
val onSecondaryDark = Color(0xFF263141)
val secondaryContainerDark = Color(0xFF3C4758)
val onSecondaryContainerDark = Color(0xFFD8E3F8)
val tertiaryDark = Color(0xFFD9BDE3)
val onTertiaryDark = Color(0xFF3D2946)
val tertiaryContainerDark = Color(0xFF543F5E)
val onTertiaryContainerDark = Color(0xFFF6D9FF)
val errorDark = Color(0xFFFFB4AB)
val onErrorDark = Color(0xFF690005)
val errorContainerDark = Color(0xFF93000A)
val onErrorContainerDark = Color(0xFFFFDAD6)
val backgroundDark = Color(0xFF111318)
val onBackgroundDark = Color(0xFFE1E2E9)
val surfaceDark = Color(0xFF111318)
val onSurfaceDark = Color(0xFFFFFFFF)
val surfaceVariantDark = Color(0xFF43474E)
val onSurfaceVariantDark = Color(0xFFC3C6CF)
val outlineDark = Color(0xFF8D9199)
val outlineVariantDark = Color(0xFF43474E)
val scrimDark = Color(0xFFFFFFFF)
val inverseSurfaceDark = Color(0xFFE1E2E9)
val inverseOnSurfaceDark = Color(0xFF2E3035)
val inversePrimaryDark = Color(0xFF3A608F)
val surfaceDimDark = Color(0xFF111318)
val surfaceBrightDark = Color(0xFF37393E)
val surfaceContainerLowestDark = Color(0xFF0C0E13)
val surfaceContainerLowDark = Color(0xFF191C20)
val surfaceContainerDark = Color(0xFF1D2024)
val surfaceContainerHighDark = Color(0xFF272A2F)
val surfaceContainerHighestDark = Color(0xFF32353A)

val primaryLight = Color(0xFF3A608F)
val onPrimaryLight = Color(0xFFFFFFFF)
val primaryContainerLight = Color(0xFFD3E3FF)
val onPrimaryContainerLight = Color(0xFF001C39)
val secondaryLight = Color(0xFF545F71)
val onSecondaryLight = Color(0xFFFFFFFF)
val secondaryContainerLight = Color(0xFFD8E3F8)
val onSecondaryContainerLight = Color(0xFF111C2B)
val tertiaryLight = Color(0xFF6D5677)
val onTertiaryLight = Color(0xFFFFFFFF)
val tertiaryContainerLight = Color(0xFFF6D9FF)
val onTertiaryContainerLight = Color(0xFF261430)
val errorLight = Color(0xFFBA1A1A)
val onErrorLight = Color(0xFFFFFFFF)
val errorContainerLight = Color(0xFFFFDAD6)
val onErrorContainerLight = Color(0xFF410002)
val backgroundLight = Color(0xFFF8F9FF)
val onBackgroundLight = Color(0xFF191C20)
val surfaceLight = Color(0xFFF8F9FF)
val onSurfaceLight = Color(0xFF000000)
val surfaceVariantLight = Color(0xFFDFE2EB)
val onSurfaceVariantLight = Color(0xFF43474E)
val outlineLight = Color(0xFF73777F)
val outlineVariantLight = Color(0xFFC3C6CF)
val scrimLight = Color(0xFF000000)
val inverseSurfaceLight = Color(0xFF2E3035)
val inverseOnSurfaceLight = Color(0xFFEFF0F7)
val inversePrimaryLight = Color(0xFFA4C9FE)
val surfaceDimLight = Color(0xFFD9DAE0)
val surfaceBrightLight = Color(0xFFF8F9FF)
val surfaceContainerLowestLight = Color(0xFFFFFFFF)
val surfaceContainerLowLight = Color(0xFFF2F3FA)
val surfaceContainerLight = Color(0xFFEDEDF4)
val surfaceContainerHighLight = Color(0xFFE7E8EE)
val surfaceContainerHighestLight = Color(0xFFE1E2E9)
